@tailwind base;
@tailwind components;
@tailwind utilities;

.light {
    /* Light mode colors */
    --bg-color: #f0f0f0;
    --text-color: #333;
    --bg-light: #fff;
    --text-light: #333;
    --hover-bg-light: #000;
    --hover-text-light: #fff;
    --expanded-bg-light: #f5f5f5;
    --sidebar-text-light: #333;
    --sidebar-hover-bg-light: #000;
    --sidebar-hover-text-light: #fff;
    --sidebar-expanded-bg-light: #f5f5f5;
    --border-color:  blue;
    --highlight-color: #ffcc00;
    --bg-logo: url('/src/assets/logo/bibot-high-resolution-logo-white-transparent.png')
  }
  
  .dark {
    /* Dark mode colors */
    --bg-color: #333;
    --text-color: #f0f0f0;
    --bg-dark: #333;
    --text-dark: #fff;
    --hover-bg-dark: #555;
    --hover-text-dark: #fff;
    --expanded-bg-dark: #444;
    --sidebar-bg-dark: #333;
    --sidebar-text-dark: #fff;
    --sidebar-hover-bg-dark: #555;
    --sidebar-hover-text-dark: #fff;
    --sidebar-expanded-bg-dark: #444;
    --border-color:  blue;
    --highlight-color: #007bff;
    --bg-logo: url('/src/assets/logo/bibot-high-resolution-logo-white-transparent.png')
  }
  
  