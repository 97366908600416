@import 'themeStyle.css';
@import 'buttonStyle.css';

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

code {
  font-family: 'Roboto', sans-serif;
}

.cardsBody, .container, .header, .display-message, .position, .embed-report, .controls, .footer, .button, .login-container, .admin-layout, .client-layout, .admin-header, .client-header, .content-layout, .title, .menu, .site-layout-background, .not-implemented, .admin-footer, .client-footer, .report-container {
  box-sizing: border-box;
}

.cardsBody {
  margin: 0;
  padding: 10px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content-layout {
  padding: 24px;
  margin: 0;
  min-height: 280px;
  /* background: green; */
}

.site-layout-background {
  padding: 24px;
  /* background-color: red; */
}

.admin-footer, .client-footer {
  text-align: center;
  background: #fff;
  padding: 10px 50px;
}

.not-implemented {
  min-height: 89.1vh;
  color: red;
  background-size: fit;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--bg-logo);
}

.ant-spin-dot-item  {
  background-color: #d67632 !important;
  color: #d67632 !important;
}

.admin-layout, .client-layout {
  flex: 1;
  overflow-y: auto;
}

.descriptions-custom .ant-descriptions-item-label {
  width: 20%;
}

.descriptions-custom .ant-descriptions-item-content {
  width: 70%;
}

h1 {
  color: #165a72;
  margin: 50px auto;
  font-size: 40px;
}
.soc-menu {
  margin-bottom: 40px;
  margin-top: 20px;
}
h2 {
  color: #165a72;
}