@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

*{
  /* font-size: 15px; */
}
.statsMain{
  display: flex;
  justify-content: center;
  align-items: center;
}
.statsCard{
  width: 40%;
}
.mimCount{
  display: flex;
  justify-content: center;
}
.mimCountCard{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  margin: 10px;
}
.FAQlist{
  display: flex;
  justify-content: center;
}
.FAQListCard{
  padding: 20px;
  width: 100%;
  margin: 10px;
 }
 
@media screen and (max-width: 600px){
  .FAQListCard{
  padding: 10px;
   width: 100%;
    margin: 0px;
    margin-top: 10px;
  }
  .statsMain{
    display: flex;
     flex-direction: column;
  }
  .statsCard{
    width: 100%;
  }
}



@media screen and (min-width: 600px) and (max-width: 900px) {
  .FAQListCard{
  padding: 10px;
    width: 100%;
     margin: 0px;
   }

  .statsMain{
    display: flex;
     flex-direction: column;
  }
  .statsCard{
    width: 100%;
  }
}

/* Media query for screens larger than 1200px */
@media screen and (min-width: 1200px) {

}

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1em;
}

.cookie-consent-banner button {
  background-color: #1f4760;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 0.5em 1em;
  cursor: pointer;
  margin-left: 1em;
}
