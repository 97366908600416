*{
  font-family: "Poppins", sans-serif !important;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 


/**NAVBAR TEST**/

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  border-bottom: 8.5px solid #1f4760;
  background-color: #fff !important;
font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding-inline: 120px;
  z-index: 1000;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-links a {
  color: #000;
  text-decoration: none;
}
.hideOnDesktop{
  display: none;
}


.nav-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .hideOnMobile{
    display: none;
  }
  .hideOnDesktop{
    display: block;
  }
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    border-bottom: 8.5px solid #1f4760;
    background-color: #fff !important;
  font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding-inline: 30px;
    z-index: 1000;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    text-align: center;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-icon {
    display: block;
  }
}

 /**NAVBAR TEST**/
.casePaper{
 padding: 120px;
 display: block;
}
.case{
  display: flex;
  gap: 2em;
}

.mainBanner{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh; 
    margin-inline: 50px;
}
a{
  text-decoration: none !important;
  color: #1f4760;
}
@keyframes popUp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  80% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.pop-up-card {
  animation: popUp 0.5s ease-out;
}

@keyframes bounce {
  0%, 20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.card{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transform: translateZ(0);
  animation: pop-in 0.3s forwards;
  cursor: pointer;
}
.card:hover {
  animation: pop-out 0.3s forwards;
}

@keyframes pop-in {
  0% {
      transform: translateY(0) scale(1);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  100% {
      transform: translateY(0) scale(1);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

@keyframes pop-out {
  0% {
      transform: translateY(0) scale(1);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  100% {
      transform: translateY(-20px) scale(1.05);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
}


.bounce-card:hover {
  animation: bounce 1s ease-in;
  cursor: pointer;
}

.bannerSectionA{
  width: 60%;
  padding: 20px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
 }
 .bannerSectionB{
  width: 40%;
  display: flex;
  justify-content: flex-start;
  /* display: block !important; */
 }
/* .navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 8.5px solid #1f4760;
    background-color: #fff !important;
  font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding-inline: 50px;
    z-index: 1000;
} */
.bannerContent{
 font-family: "Poppins", sans-serif !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-inline: 120px;
  flex: 1;
  margin-top: 0px;
}
.IntroText{
 font-family: "Poppins", sans-serif !important;
 font-weight: 250 !important;
 margin-bottom: 20px !important;
 font-size: 50px !important;
}
.features{
  height: '100%';
  padding-inline: 120px;
  margin-top: 40px;
  padding-bottom: 50;
  border-radius: 0 !important;
}
.featureCardMainParent{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 80px;
}
.featureCard{
  height: 400px;
  width:  260px;
  margin-top: 20px;
}
.useCaseParent{
  text-align: center ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px;
  padding-bottom: 50;
  border-radius: 0 !important;
}

.CaseCardParent{
  margin-top: 30px !important;
  display: flex;
  margin-top: 10px;
}
.pricingParent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px;
  padding-bottom: 50px;
  border-radius: 0 !important;
}
.pricingCardParent{
  display: flex !important;
  justify-content: center;
}
.pricingCard{
  width: 100% !important;
}
.footerContent{
  padding: 40px;
  display: flex;
 flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.socialMediaIcons{
  margin-top: 20px;
}


@media screen and (max-width: 600px){
  .hideOnDesktop{
    display: block;
  }
  .case{
    display: block;
    gap: 1em;
    margin-bottom: 2em;
  }
  .casePaper{
    padding: 50px;
   }
  
  .mainBanner{
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh; 
    margin-inline: 0px;
}
  .bannerContent{
    font-family: "Poppins", sans-serif !important;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
     margin: 20px;
     flex: 1
   }
   .IntroText{
    font-family: "Poppins", sans-serif !important;
    font-weight: 250 !important;
    margin-bottom: 20px !important;
    font-size: 30px !important;
   }
   .bannerSectionA{
    width: 100%;
    padding: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
   }
 
   .bannerSectionB{
    display: none !important;
   }
   .features{
    height: '100%';
    padding-inline: 10px;
    margin-top: 20em;
    padding-bottom: 0;
    border-radius: 0;
  }
  .featureCardMainParent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    padding-bottom: 80px;
  }
  .featureCard{
    height: 400px;
    width:  300px;
  }
  .useCaseParent{
    text-align: center ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    padding-bottom: 50;
    border-radius: 0 !important;
  }
  .CaseCardParent{
    display: flex;
    flex-direction: column !important;
    margin-top: 10px;
  }
  .pricingParent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    padding-bottom: 50px;
    border-radius: 0 !important;
  }
  .pricingCardParent{
    width: 100%;
    display: flex;
   flex-wrap: wrap;
    justify-content: center;
  }
  .pricingCard{
    width: 100%;
    margin-bottom: 20px !important;
  }
  .footerContent{
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
  }

  }
  
  
  @media screen and (min-width: 600px) and (max-width: 900px) {
    .hideOnDesktop{
      display: block;
    }
    
    .case{
      display: block;
      gap: 1em;
      margin-bottom: 2em;
    }
    .casePaper{
      padding: 50px;
     }
   
    .mainBanner{
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100vh; 
      margin-inline: 0px;
  }
  .useCaseParent{
    text-align: center ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
    padding-bottom: 50;
    border-radius: 0 !important;
  }
  
  .bannerContent{
    font-family: "Poppins", sans-serif !important;
     display: flex;
     justify-content: center;
     align-items: center;
     text-align: center;
     margin: 20px;
     flex: 1
   }
     .IntroText{
      font-family: "Poppins", sans-serif !important;
      font-weight: 250 !important;
      margin-bottom: 20px !important;
      font-size: 45px !important;
     }
     .bannerSectionA{
      width: 100%;
      padding: 0px;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
     }
     .bannerSectionB{
      display: none !important;
     } 
     .features{
      height: '100%';
      padding-inline: 10px;
      margin-top: 100px;
      padding-bottom: 0;
      border-radius: 0;
    }
    .featureCardMainParent{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      padding-bottom: 80px;
    }
    .featureCard{
      height: 400px;
      width:  400px;
    }
    .CaseCardParent{
      display: flex;
      flex-direction: column !important;
      margin-top: 10px;
    }
    .pricingCardParent{
      width: 100%;
      display: flex;
     flex-wrap: wrap;
      justify-content: center;
    }
    .pricingCard{
      width: 100% !important;
      margin-bottom: 20px !important;
    }
  
  }